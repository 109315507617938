// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

require("trix")
require("@rails/actiontext")
require("jquery")
require("jquery-ui")
require('bootstrap/dist/js/bootstrap')
window.$ = $

require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import moment from 'moment/moment'
//window.moment = moment
//import 'moment/locale/de-ch'

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('#datum').datepicker({ dateFormat: "dd.mm.yy" })
  //$.fn.dataTable.moment( "dd.mm.yy" )
  $('#sortable').DataTable();
})

